import React, { useEffect, useState } from 'react'
import { CloseModalIcon } from '../icons'
import {
  Form,
  Input,
  message,
  Modal,
  Select,
  Slider,
  Radio,
  Dropdown,
  Checkbox,
  Tooltip,
} from 'antd'
import axios from 'axios'
import GooglePlacesAutocomplete from '../google-place-autocomplete'
import { strapiURL } from '../../config'
import {
  emailFrequencyCandidate,
  emailFrequencyJobAlert,
  CompensationType,
  valuesSetHandler,
  jobFunctions,
  jobTravelFunction,
  jobCategories,
  EMPLOYER_TYPES,
  SELECT_ALL,
  RESUME_SEARCH_DISTANCE_OPTIONS,
} from '../../data'
import {
  restructorArray,
  displayValueHandler,
  getDecodedLocation,
} from '../../functions'

const { Option } = Select

const SearchSaveModal = ({
  modalOpen,
  setModalOpen,
  userProfile,
  editItem,
  setEditItem,
  setCurrentData,
  currentData,
}) => {
  const [form] = Form.useForm()
  const compensationValue = Form.useWatch('compensation', form)
  const [activeFilter, setActiveFilter] = useState({})
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [onlyComp, setOnlyComp] = useState(false)

  let alertUrl =
    typeof window !== 'undefined' &&
    window.location.href.indexOf('alerts/edit') > -1

  const emailFrequency = alertUrl
    ? emailFrequencyJobAlert
    : emailFrequencyCandidate

  const closeHandler = () => {
    if (setModalOpen) {
      setModalOpen(false)
    }
    if (setEditItem) {
      setEditItem(null)
      form.resetFields()
    }
  }

  useEffect(() => {
    if (editItem) {
      let formValues = {}
      formValues = {
        name: editItem?.name || '',
        email: editItem?.email || '',
        keyword: editItem?.keyword || '',
        compensation_type: editItem?.compensation_type
          ? editItem?.compensation_type.split(',')
          : [],
        primary_function: editItem?.primary_function || '',
        frequency: editItem?.frequency || '',
        travel_percentage: editItem?.travel_percentage || '',
        product_category: editItem?.product_category || '',
        employer_type: editItem.employer_type,
        remote_enabled: editItem?.remote_enabled || false,
        field_based: editItem?.field_based || false,
        distance: editItem?.distance,
        location: editItem?.location?.placePredict,
      }
      if (editItem?.max_compensation && editItem?.min_compensation) {
        formValues.compensation = [
          editItem?.min_compensation,
          editItem?.max_compensation,
        ]
      } else if (!editItem?.max_compensation && editItem?.min_compensation) {
        formValues.compensation = [editItem?.min_compensation]
      } else if (editItem?.max_compensation && !editItem?.min_compensation) {
        formValues.compensation = [0, editItem?.max_compensation]
      }

      form.setFieldsValue(formValues)
      setOnlyComp(editItem.only_comp)
    }
  }, [editItem])

  const onFinish = async values => {
    setConfirmLoading(true)
    let data = {
      name: values.name,
      keyword: values.keyword,
      email: values.email,
      frequency: values.frequency,
      compensation_type: values.compensation_type.join(','),
      distance: values.distance,
      primary_function: values.primary_function?.toString(),
      product_category: values.product_category?.toString(),
      employer_type: values.employer_type?.toString(),
      field_based: values.field_based,
      remote_enabled: values.remote_enabled,
      travel_percentage: values.travel_percentage?.toString(),
      candidate_profile: userProfile ? userProfile.id : '',
      min_compensation: values.compensation?.[0],
      max_compensation: values?.compensation?.[1],
      only_comp: onlyComp,
    }
    if (values.location && values.location?.value?.place_id) {
      const decodedLocation = await getDecodedLocation(
        values.location?.value?.place_id
      )
      if (decodedLocation) {
        data.location = {
          location: decodedLocation,
          placePredict: values.location,
        }
      }
    }
    axios
      .put(
        alertUrl
          ? strapiURL + `/update-job-alerts-email/${editItem?.id}`
          : strapiURL + `/candidate-searches/${editItem?.id}`,
        {
          ...data,
        }
      )
      .then(res => {
        if (currentData) {
          let _copyAll = [...currentData]
          _copyAll[_copyAll.findIndex(item => item?.id === editItem?.id)] =
            res.data

          setCurrentData(_copyAll)
        }
        closeHandler()
        message.success('Search saved')
        setConfirmLoading(false)
        closeHandler()
        form.resetFields()
      })
      .catch(err => {
        message.error('Something went wrong')
        setConfirmLoading(false)
        closeHandler()
        form.resetFields()
      })
  }

  const selectAllParamHandler = arr => {
    if (arr?.length) {
      const selectAllIndex = arr.indexOf(valuesSetHandler(SELECT_ALL))
      if (selectAllIndex > -1) {
        arr?.splice(selectAllIndex, 1)
      }
    }
    return arr
  }

  const Menu = ({ items, name, includeSelectAll }) => {
    let isSelectAll = false
    let isSelectAllChecked = false
    let selectAllKey = valuesSetHandler(SELECT_ALL)
    if (includeSelectAll && items.indexOf(selectAllKey) == -1) {
      items.splice(0, 0, selectAllKey)
    }

    let _resturcturedData = restructorArray(items, 6)
    let _currentArray = { ...form.getFieldsValue() }
    let currentItem = []
    let selectedKeyValue = _currentArray[name]
    if (selectedKeyValue) {
      currentItem = Array.isArray(selectedKeyValue)
        ? selectedKeyValue
        : selectedKeyValue.split(',')
    }
    let availableValues = []
    _resturcturedData?.map(resItem => {
      resItem?.map(item => {
        availableValues.push(valuesSetHandler(item))
      })
    })

    if (
      includeSelectAll &&
      currentItem &&
      currentItem.length === availableValues.length - 1 &&
      currentItem.indexOf(selectAllKey) == -1
    ) {
      currentItem.splice(0, 0, selectAllKey)
    }

    return (
      <div className="shadow p-6 rounded-md search-filter-dropdown bg-white relative">
        <button
          className="text-merlot absolute right-0 top-0 mr-4 mt-4"
          onClick={e => {
            setActiveFilter({})
          }}
        >
          <CloseModalIcon />
        </button>
        <Checkbox.Group
          value={currentItem}
          className="w-full"
          onChange={values => {
            form.setFieldsValue({
              [name]: isSelectAll
                ? isSelectAllChecked
                  ? availableValues
                  : []
                : selectAllParamHandler(values),
            })
          }}
        >
          {_resturcturedData?.map((resItem, resItemIndex) => {
            return (
              <div
                className="flex flex-col mr-12 theme-form-item"
                key={resItemIndex}
              >
                {resItem &&
                  resItem?.map((item, index) => {
                    return (
                      <Checkbox
                        checked={true}
                        className="search-filter-dropdown-item"
                        key={index}
                        value={valuesSetHandler(item)}
                        onChange={e => {
                          isSelectAll = e.target.value === selectAllKey
                          isSelectAllChecked = e.target.checked
                        }}
                      >
                        {item === 'Select_All' ? 'Select All' : item}
                      </Checkbox>
                    )
                  })}
              </div>
            )
          })}
        </Checkbox.Group>
      </div>
    )
  }

  const DropdownButton = ({ icon, label, items, name, includeSelectAll }) => {
    let isActive = activeFilter.name === name && activeFilter.visible
    let selectedValues = { ...form.getFieldsValue() }
    return (
      <Form.Item
        name={name}
        className="theme-form-item w-full sm:w-[48%] mt-4 ant-form-item-has-success"
        label={label}
      >
        <Dropdown
          visible={isActive}
          onVisibleChange={val => {
            setActiveFilter({
              name: name,
              visible: val,
            })
          }}
          trigger={['click']}
          overlay={
            <Menu
              data="test"
              items={items}
              name={name}
              includeSelectAll={includeSelectAll}
            />
          }
        >
          <button
            className={`${
              isActive ? 'border border-merlot' : 'border border-transparent'
            } filter-option flex items-start shadow rounded-md cursor-pointer w-full`}
          >
            <div className="text-side py-3 px-4 border-r border-filterb w-[80%]">
              <p
                className={`${
                  isActive ? ' text-merlot' : 'text-black'
                } font-sans mb-0 text-sm text-left`}
              >
                {label}
              </p>
            </div>
            <div className="icon-side py-3 px-4 w-[20%] flex justify-end items-center pl-12">
              <img src={`/icons/${icon}.svg`} className="mb-0" name={name} />
            </div>
          </button>
        </Dropdown>
      </Form.Item>
    )
  }

  return (
    <>
      <Modal
        className="p-0 candidateAlertModal shadow-lg"
        width={1040}
        footer={null}
        visible={modalOpen}
        confirmLoading={confirmLoading}
        onCancel={closeHandler}
      >
        <div className="modal-head bg-voyage py-5 px-5 rounded-tl rounded-tr flex items-center justify-between">
          <p className="font-sans font-bold text-base text-white mb-0">
            Edit Alert
          </p>
          <img
            onClick={() => closeHandler()}
            className="w-4 cursor-pointer mb-0"
            src="/icons/icon-modal-close.svg"
            alt="Close modal icon"
          />
        </div>
        <div className="bg-white px-6 py-8 rounded-bl rounded-br">
          <Form
            layout="vertical"
            form={form}
            name="educationHistory"
            onFinish={onFinish}
          >
            <div className="flex md:flex-row flex-col md:flex-wrap justify-between flex-wrap">
              <Tooltip title="This will be the name of your search so that you can identify it and, if needed, edit or alter it later.">
                <Form.Item
                  className="theme-form-item w-full sm:w-[48%]"
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Tooltip>
              <Form.Item
                className="theme-form-item w-full sm:w-[48%]"
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: 'Required',
                  },
                  {
                    type: 'email',
                    message: 'Please enter valid email',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                className="theme-form-item w-full sm:w-[48%]"
                name="keyword"
                label="Keyword"
              >
                <Input />
              </Form.Item>
              <Form.Item
                className="theme-form-item w-full sm:w-[48%]"
                name="compensation_type"
                label="Compensation Type"
              >
                <Select mode="multiple" defaultValue="">
                  {CompensationType &&
                    CompensationType?.map((type, index) => {
                      return (
                        <Option key={index} value={valuesSetHandler(type)}>
                          {type}
                        </Option>
                      )
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                className="theme-form-item w-full sm:w-[48%]"
                name="frequency"
                label="Frequency"
                rules={[
                  {
                    required: true,
                    message: 'Required',
                  },
                ]}
              >
                <Select>
                  {emailFrequency &&
                    emailFrequency?.map((item, index) => {
                      return (
                        <Option value={item} key={index}>
                          {item}
                        </Option>
                      )
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                name="location"
                label="Location"
                className="google-place-autocomplete w-full sm:w-[48%] mb-8"
              >
                <GooglePlacesAutocomplete />
              </Form.Item>
              <Form.Item
                name="distance"
                label="Distance"
                className="theme-form-item w-full sm:w-[48%] mb-4 lg:mb-0 ml-0 red-select"
              >
                <Select placeholder="Enter Featured" defaultValue={'any'}>
                  {RESUME_SEARCH_DISTANCE_OPTIONS.map(option => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <div className="flex gap-4 mt-8 w-full sm:w-[48%]">
                <Form.Item
                  valuePropName="checked"
                  name="remote_enabled"
                  className="theme-form-item mb-0 "
                >
                  <Checkbox className="search-compensation-radio text-charcoal font-sans">
                    Remote
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  valuePropName="checked"
                  name="field_based"
                  className="theme-form-item mb-0 "
                >
                  <Checkbox className="search-compensation-radio text-charcoal font-sans">
                    Field
                  </Checkbox>
                </Form.Item>
                <div className="theme-form-item mb-0 pt-1.5">
                  <Checkbox
                    checked={!onlyComp}
                    onChange={evt => setOnlyComp(!evt.target.checked)}
                    className="search-compensation-radio text-charcoal font-sans"
                  >
                    Include Jobs without Compensation
                  </Checkbox>
                </div>
              </div>
              <Form.Item
                name="compensation"
                className="theme-form-item w-full sm:w-[35%] mx-auto mb-0 multiple-slider mt-4"
              >
                <label className="theme-form-custom-label w-full flex justify-center text-center">
                  Compensation Range
                </label>
                <div className="flex items-center justify-between mt-0">
                  <span className="font-semibold text-sm font-sans">
                    ${compensationValue?.[0]}K
                  </span>
                  <span className="font-semibold text-sm font-sans">
                    ${compensationValue?.[1]}K
                  </span>
                </div>
                <div className="mt-0">
                  <Slider
                    range
                    max={500}
                    min={0}
                    value={form.getFieldValue('compensation')}
                    onChange={val => form.setFieldsValue({ compensation: val })}
                  />
                </div>
              </Form.Item>
              <DropdownButton
                icon="travel-percentage-icon"
                label="Travel Percentage"
                items={jobTravelFunction}
                name="travel_percentage"
                includeSelectAll
              />
              <DropdownButton
                icon="icon-primary-function"
                label="Primary Function"
                items={jobFunctions}
                name="primary_function"
              />
              <DropdownButton
                icon="category-icon"
                label="Industry"
                items={jobCategories}
                name="product_category"
              />
              <DropdownButton
                icon="icon-primary-function"
                label="Employer Type"
                items={EMPLOYER_TYPES}
                name="employer_type"
              />
            </div>
            <div className="flex items-center mt-8">
              <button
                onClick={() => closeHandler()}
                type="button"
                className="bg-transparent focus:outline-none text-merlot"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-bgSecondaryButton py-3 px-6 text-voyage font-bold rounded text-xs font-sans ml-4"
              >
                {editItem
                  ? confirmLoading
                    ? 'Updating...'
                    : 'Update'
                  : confirmLoading
                  ? 'Creating...'
                  : 'Create Alert'}
              </button>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  )
}
export default SearchSaveModal
